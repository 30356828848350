<template>
  <div class="ExamDistribute">
    <detailsHeader
      ref="detailsHeader"
      router-url="/exam/unionExam"
      :query="{
        examId: $route.query.examId,
      }"
    >
      <!-- <el-button type="primary" :loading="btnLoading" @click="goBack">
        保存设置
      </el-button> -->
    </detailsHeader>
    <div class="exam-distribute">
      <p class="exam-distribute-title" style="margin-top: 0">考试分工：</p>
      <el-table :data="examList" border style="width: 100%">
        <el-table-column
          prop="name"
          label="功能名称"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="tips"
          label="权限描述"
          width="309"
          align="left"
          header-align="center"
        >
        </el-table-column>
        <el-table-column prop="teacherIds" label="教师名单" align="center">
          <template slot-scope="scope">
            <div class="teacher-item">
              <div class="tab-teacher-list">
                <div
                  v-for="(v, i) in scope.row.teacherList"
                  :key="i"
                  class="user-list"
                >
                  {{ v.name }}
                </div>
              </div>
              <div
                v-if="scope.row.teacherList"
                class="btn-icon"
                @click="showAddTeacher(scope.row)"
              >
                <img
                  v-if="scope.row.teacherList.length == 0"
                  src="@/assets/uExam/icon_add.png"
                  alt=""
                />
                <img v-else src="@/assets/uExam/icon_edit.png" alt="" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="180" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              :disabled="
                !scope.row.teacherList || scope.row.teacherList.length == 0
              "
              @click="clearRow(scope.row)"
            >
              清空
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <p class="exam-distribute-title">科目分工：</p>
      <el-table
        v-loading="listLoading"
        class="subject-distribute-table"
        :data="subjectList"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="subjectName"
          label="科目"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <div
              class="el-table-box el-table-subject"
              @click="showSetAllList(scope.row, 2)"
            >
              <span class="subject-name">
                {{ scope.row.subjectName }}
              </span>
              <el-tooltip
                class="item"
                effect="dark"
                content="批量设置"
                placement="top"
              >
                <img src="@/assets/uExam/icon_setting.png" alt="" />
              </el-tooltip>
              <!-- <el-button plain icon="el-icon-setting"> 批量设置 </el-button> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in subjectListTitle"
          :key="index"
          prop="teacherIds"
          :label="item.name"
          align="center"
        >
          <template slot="header">
            <div
              class="el-table-box"
              @click="showSetAllList(item.subjectType, 1)"
            >
              {{ item.name }}
              <el-tooltip
                class="item"
                effect="dark"
                content="批量设置"
                placement="right"
              >
                <img src="@/assets/uExam/icon_setting.png" alt="" />
              </el-tooltip>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="teacher-item">
              <div class="tab-teacher-list">
                <template v-if="scope.row[item.key]">
                  <div
                    v-for="(v, i) in scope.row[item.key].teacherList"
                    :key="i"
                    class="user-list"
                  >
                    {{ v.name }}
                  </div>
                </template>
              </div>
              <div
                v-if="scope.row[item.key].teacherList"
                class="btn-icon"
                @click="showSetAllList(scope.row[item.key], 3)"
              >
                <img
                  v-if="scope.row[item.key].teacherList.length == 0"
                  src="@/assets/uExam/icon_add.png"
                  alt=""
                />
                <img v-else src="@/assets/uExam/icon_edit.png" alt="" />
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="批量设置该权限教师"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <div class="search-box">
        <div class="search-item">
          学校：
          <el-select
            v-model="modelSearch.schoolUnionId"
            placeholder="请选择学校"
            filterable
            @change="selectChange"
          >
            <el-option label="全部学校" value=""> </el-option>
            <el-option
              v-for="item in schoolList"
              :key="item.schoolId"
              :label="item.schoolName"
              :value="item.schoolId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          学段：
          <el-select
            v-model="modelSearch.level"
            placeholder="请选择学段"
            @change="levelChange"
          >
            <el-option label="全部" value=""> </el-option>
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="examInformation.examType != 1" class="search-item">
          <!-- yearHignOptions yearMiddleOptions -->
          入学年份：
          <el-select
            v-model="modelSearch.year"
            placeholder="请选择入学年份"
            clearable
            @change="yearChange"
          >
            <el-option label="全部" value=""> </el-option>
            <el-option
              v-for="item in yearList"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <el-select
            v-else
            v-model="modelSearch.year"
            placeholder="请选择入学年份"
            clearable
            @change="yearChange"
          >
            <el-option
              v-for="item in yearHignOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select> -->
        </div>
        <div class="search-item">
          分类：
          <el-select
            v-model="modelSearch.type"
            placeholder="全部"
            @change="selectChange"
          >
            <el-option label="全部" value=""> </el-option>
            <el-option
              v-for="item in typeOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div v-if="examInformation.examType != 1" class="search-item ts-item">
          班级：
          <el-select
            v-model="modelSearch.classNum"
            placeholder="请选择班级"
            @change="selectChange"
          >
            <el-option
              v-for="item in classOptions"
              :key="item.id"
              :label="item.classNum"
              :value="item.classNum"
            >
            </el-option>
          </el-select>
        </div> -->
        <div class="search-item">
          科目：
          <el-select
            v-model="modelSearch.subjectId"
            placeholder="请选择科目"
            clearable
            @change="selectChange"
          >
            <el-option label="全部" value=""> </el-option>
            <el-option label="无科目" value="-1"> </el-option>
            <el-option
              v-for="item in subjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div v-if="examInformation.examType != 1" class="search-item">
          角色权限：
          <el-select
            v-model="modelSearch.roleId"
            placeholder="请选择"
            clearable
            @change="selectChange"
          >
            <el-option
              v-for="item in teacherRole"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <el-input
            v-model="modelSearch.name"
            class="search-input"
            placeholder="请输入搜索内容"
            @keyup.enter.native="normalFetchList"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="normalFetchList"
            >
            </i
          ></el-input>
        </div>
      </div>
      <div class="teacher-box">
        <div>
          <div class="teacher-header">
            <el-button type="text" @click="changeChecked(1)"> 全选 </el-button>
            <el-button type="text" @click="changeChecked(2)"> 反选 </el-button>
          </div>
          <div class="teacher-list-box edit-scroll-style">
            <el-checkbox-group
              v-model="checkTeacherList"
              @change="setTeacherList"
            >
              <template>
                <!--               v-if="checkTeacherList.indexOf(v.id) == -1" -->
                <el-checkbox
                  v-for="(v, i) in teacherList"
                  :key="i"
                  :label="v.id"
                >
                  {{ v.name }}({{ v.username }},{{ v.schoolName }})
                </el-checkbox>
              </template>
            </el-checkbox-group>
          </div>
        </div>
        <div>
          <div class="teacher-header right-teacher-header">
            <el-button type="text" @click="clear()">清空</el-button>
          </div>
          <div class="teacher-list-box edit-scroll-style">
            <template>
              <div
                v-for="(v, i) in modelTeacherList"
                :key="i"
                class="teacher-list"
              >
                {{ v.name }}
                <i class="el-icon-close" @click="clear(v)"></i>
              </div>
            </template>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="save">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <editUser ref="editUser"></editUser>
  </div>
</template>

<script>
const examList = [
  // {
  //   name: "考生管理",
  //   teacherIds: "",
  //   type: 1,
  // },
  // {
  //   name: "讲评报表",
  //   teacherIds: "",
  //   type: -1,
  // },
  {
    name: "扫描管理",
    teacherIds: "",
    tips: "能够查看全部扫描点信息以及扫描进度，处理全部扫描异常。",
    type: 2,
  },
  {
    name: "临时成绩榜",
    teacherIds: "",
    tips: "能够查看临时榜成绩数据。",
    type: 5,
  },
];
const subjectListTitle = [
  {
    name: "阅卷分工",
    key: "subjectTypeWork",
    subjectType: 3,
  },
  {
    name: "监控进度",
    key: "subjectTypeSchedule",
    subjectType: 8,
  },
  // {
  //   name: "阅卷开关",
  //   key: "subjectTypeSwitch",
  //   subjectType: 6,
  // },
  {
    name: "阅卷质量",
    key: "subjectTypeQuality",
    subjectType: 9,
  },
];
// const subjectList = [
//   {
//     name: "考生管理",
//     teacherIds: "",
//     type: 9,
//   },
//   {
//     name: "统计参数设置",
//     teacherIds: "",
//     type: 9,
//   },
//   {
//     name: "临时成绩榜",
//     teacherIds: "",
//     type: 9,
//   },
// ];
import { getObj } from "@/core/api/exam/exam";
import {
  getExamunionwork,
  saveExamunionwork,
  examunionteacher,
  unionExistschool,
} from "@/core/api/exam/examDistribute";
import { getSubjectList, getYear } from "@/core/util/util";
import { levelOptions, teacherRole } from "@/core/util/constdata";

import {
  getGradeList,
  getClassListByLevelAndYear,
} from "@/core/api/school/schoolgradeclass";

import detailsHeader from "@/components/detailsHeader.vue";
// import { schoolteachertype } from "@/core/api/exam/examTeacher";
import { schoolunionuserType } from "@/core/api/cms/geo.js";
import editUser from "./components/editUser.vue";
// levelOptions
export default {
  name: "ExamDistribute",
  components: {
    detailsHeader,
    editUser,
  },
  data() {
    return {
      yearList: getYear(),
      listLoading: false,
      dialogVisible: false,
      examList: examList,
      btnLoading: false,
      subjectListTitle: subjectListTitle,
      examInformation: {
        name: "",
      },
      subjectList: [],
      //弹窗数据 start yearHignOptions yearMiddleOptions
      checkedAll: false,
      checkedFalse: false,
      checkTeacherList: [],
      teacherList: [],
      yearMiddleOptions: [],
      yearHignOptions: [],
      classOptions: [],
      subjectOptions: [],
      levelOptions: levelOptions(),
      teacherRole: teacherRole,
      modelSearch: {
        level: "",
        subjectId: "",
        year: "",
        roleId: "",
        classNum: "",
        name: "",
        schoolUnionId: "",
        type: "",
      },
      schoolId: "",
      schoolList: [],
      modelTeacherList: [],
      typeOptions: [],
      // 弹窗数据 end
    };
  },
  created() {
    this.getExamInfo();
    this.subjectOptions = getSubjectList(this.$route.query.levelId);
    this.getSchoolYearList();
    this.getSchoolunionuserType();
    // this.normalFetchList();
  },
  methods: {
    async getSchoolunionuserType() {
      const res = await schoolunionuserType({
        examId: this.$route.query.examId,
      });
      this.typeOptions = res.data.data;
    },
    setTeacherList() {
      this.teacherList.map((item) => {
        if (this.checkTeacherList.indexOf(item.id) != -1) {
          this.modelTeacherList.push(item);
        } else {
          this.modelTeacherList = this.modelTeacherList.filter(
            (index) => index.id != item.id
          );
        }
      });
      // this.teacherList.map(item=>)
      this.modelTeacherList = this.deWeight(this.modelTeacherList);
    },
    async getExamSchools() {
      const res = await unionExistschool({
        examId: this.examInformation.examId,
      });
      this.schoolList = res.data.data;
    },
    // @change="selectChange"
    selectChange() {
      this.normalFetchList();
    },
    clearRow(row) {
      let data = JSON.parse(JSON.stringify(row));
      // data.teacherIds = "";
      // data.subjectIds = 0;
      // data.subjectTypes = 0;
      // this.$refs.delVerify.init(data);
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [
          h("span", null, "此操作将永久清空当前用户，是否继续? "),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            data.teacherIds = "";
            data.subjectIds = 0;
            data.subjectTypes = 0;

            this.clearTeacher(data, instance, done);
          } else {
            done();
          }
        },
      })
        .then(() => {})
        .catch(() => {});
    },
    clearTeacher(data, instance, done) {
      saveExamunionwork(data)
        .then(() => {
          instance.confirmButtonLoading = false;
          this.$message({
            showClose: true,
            message: "清空成功!",
            type: "success",
          });
          done();
          this.getExamunionwork();
        })
        .catch(() => {
          instance.confirmButtonLoading = false;
        });
    },
    yearChange() {
      // this.changeClassOptions();
      this.normalFetchList();
    },
    levelChange() {
      this.subjectOptions = getSubjectList(this.modelSearch.level);
      let filterList = this.subjectOptions.filter(
        (item) => this.modelSearch.subjectId == item.subjectId
      );
      if (filterList.length == 0) {
        this.modelSearch.subjectId = "";
      }
      this.normalFetchList();
    },

    // 数组去重
    deWeight(arr) {
      let obj = {};
      let set = arr.reduce((cur, next) => {
        obj[next.id] ? "" : (obj[next.id] = true && cur.push(next));
        return cur;
      }, []);
      return set;
    },
    // 批量设置所有科目的弹窗
    showSetAllList(val, type) {
      this.checkTeacherList = [];
      this.modelTeacherList = [];
      // 当前科目分工的类型 type 1 就是设置批量所有科目 2批量设置单个科目
      if (type == 1) {
        // 当前科目分工类型 0就是所有类型
        this.indexSubJectType = val;
        // 当前的科目 0就是所有科目
        this.indexsubjectId = 0;

        this.subjectList.map((index) => {
          this.subjectListTitle.map((item) => {
            if (index[item.key].subjectType == val) {
              index[item.key].teacherList.forEach((i) => {
                this.modelTeacherList.push(i);
                this.checkTeacherList.push(i.id);
              });
            }
          });
        });
      }
      if (type == 2) {
        // 当前科目分工类型 0就是所有类型
        this.indexSubJectType = 0;
        // 当前的科目
        this.indexsubjectId = val.subjectId;
        // console.log(val);
        this.subjectList.map((index) => {
          if (index.subjectId == val.subjectId) {
            this.subjectListTitle.map((item) => {
              index[item.key].teacherList.forEach((item) => {
                this.modelTeacherList.push(item);
                this.checkTeacherList.push(item.id);
              });
            });
          }
        });
      }
      if (type == 3) {
        // 当前科目分工类型 0就是所有类型
        this.indexSubJectType = val.subjectType;
        // 当前的科目
        this.indexsubjectId = val.subjectId;
        val.teacherList.forEach((item) => {
          this.modelTeacherList.push(item);
          this.checkTeacherList.push(item.id);
        });
      }
      this.checkTeacherList = [...new Set(this.checkTeacherList)];
      this.modelTeacherList = this.deWeight(this.modelTeacherList);
      // 科目分工将type设置为9
      this.indexSetting = {};
      this.indexSetting.type = 9;
      // this.dialogVisible = true;
      this.$refs.editUser.init();
    },
    // 保存当前老师
    save() {
      let data = this.indexSetting || {};
      data.teacherIds = this.modelTeacherList.map((item) => item.id).join(",");
      data.teacherList = [];
      data.examId = this.examInformation.examId;
      //小于9就是考务权限
      if (data.type < 9) {
        data.subjectTypes = 0;
        data.subjectIds = 0;
      }
      // 设置科目权限
      if (data.type == 9) {
        // indexsubjectId等于0就是所有科目
        if (this.indexsubjectId == 0) {
          data.subjectIds = this.examInformation.subjectIds;
        } else {
          data.subjectIds = this.indexsubjectId.toString();
        }
        // indexSubJectType如果为0 就是设置所有类型
        if (this.indexSubJectType == 0) {
          let subjectType = this.subjectListTitle.map(
            (item) => item.subjectType
          );
          data.subjectTypes = subjectType.join(",");
        } else {
          data.subjectTypes = this.indexSubJectType.toString();
        }
      }
      this.btnLoading = true;
      saveExamunionwork(data)
        .then(() => {
          this.btnLoading = false;
          this.dialogVisible = false;
          this.getExamunionwork();
        })
        .catch(() => {
          this.btnLoading = false;
        });
      // this.teacherList
    },
    // 删除老师
    clear(v) {
      if (!v) {
        this.checkTeacherList = [];
        this.modelTeacherList = [];
      } else {
        this.checkTeacherList = this.checkTeacherList.filter(
          (item) => item != v.id
        );
        this.modelTeacherList = this.modelTeacherList.filter(
          (item) => item.id != v.id
        );
      }
    },
    //  changeCheckedAll changeCheckedFalse
    //全选 反选
    changeChecked(type) {
      // type 等于1就是全选  2就是反选
      if (type == 1) {
        this.checkedFalse = false;
        this.checkTeacherList = [];

        this.teacherList.forEach((index) => {
          this.checkTeacherList.push(index.id);
        });
      } else {
        this.checkedAll = false;
        let array = [];
        this.teacherList.forEach((index) => {
          if (
            this.checkTeacherList.filter((item) => item == index.id).length == 0
          )
            array.push(index.id);
        });
        this.checkTeacherList = array;
      }
      this.setTeacherList();
    },
    // 获取班级列表
    changeClassOptions() {
      if (this.modelSearch.level && this.modelSearch.year) {
        let query = {
          level: this.modelSearch.level,
          year: this.modelSearch.year,
        };
        getClassListByLevelAndYear(query).then((response) => {
          this.modelSearch.classNum = "";
          this.classOptions = response.data.data;
        });
      }
    },
    //获取老师信息
    normalFetchList() {
      this.modelSearch.examId = this.$route.query.examId;
      examunionteacher(this.modelSearch).then((response) => {
        this.teacherList = response.data.data;
      });
    },
    // 获取所有年级列表
    getSchoolYearList() {
      getGradeList().then((response) => {
        let grades = response.data.data;
        if (grades && grades.length > 0) {
          grades.forEach((item) => {
            if (item.level == 2) {
              this.yearMiddleOptions.push(item.year);
            } else {
              this.yearHignOptions.push(item.year);
            }
          });
          this.yearHignOptions = [...new Set(this.yearHignOptions)];
          this.yearMiddleOptions = [...new Set(this.yearMiddleOptions)];
        }
      });
    },
    //显示添加教师弹窗
    showAddTeacher(val) {
      this.indexSetting = JSON.parse(JSON.stringify(val));
      this.checkTeacherList = this.indexSetting.teacherList.map(
        (item) => item.id
      );

      this.modelTeacherList = this.indexSetting.teacherList;
      this.$refs.editUser.init();
    },
    //获取考试基本信息
    getExamunionwork() {
      let data = {
        examIds: this.$route.query.examId,
      };
      this.listLoading = true;
      this.initTab();
      getExamunionwork(data)
        .then((res) => {
          // 考试分工列表
          this.listLoading = false;
          this.examList.map((index) => {
            index.teacherList = [];
            res.data.data.examUnionWork.forEach((item) => {
              item.teacherList = item.teacherNames;
              if (index.type == item.type) {
                Object.assign(index, item);
              }
            });
          });
          // console.log(JSON.parse(JSON.stringify(this.examList)));
          this.examList = JSON.parse(JSON.stringify(this.examList));
          // 科目分工列表
          let subjectWork = res.data.data.subjectUnionWork;

          let subjectList = JSON.parse(JSON.stringify(this.subjectList));
          subjectList = subjectList.map((item) => {
            subjectWork.map((index) => {
              if (index.subjectId == item.subjectId) {
                this.subjectListTitle.map((i) => {
                  if (item[i.key].subjectType == index.subjectType) {
                    item[i.key].teacherList = index.teacherNames;
                  }
                });
              }
            });
            return item;
          });
          this.subjectList = subjectList;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    initTab() {
      let subjectListArr = [];
      subjectListArr = this.paperList.map((item) => {
        let data = {
          subjectName: item.subjectName,
          subjectId: item.subjectId,
        };
        this.subjectListTitle.map((index) => {
          let json = JSON.parse(JSON.stringify(index));
          json.teacherList = [];
          json.subjectId = item.subjectId;
          data[index.key] = json;
        });

        return data;
      });
      this.subjectList = subjectListArr;
    },
    //获取考试基本信息
    getExamInfo() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        this.paperList = res.data.data.paperList;
        this.paperIdIndex = this.paperList[0].paperId;
        this.modelSearch.examPaperId = this.paperIdIndex;
        this.modelSearch.level = res.data.data.level.toString();
        this.modelSearch.year = res.data.data.year.toString();
        if (this.examInformation.examType == 1) {
          this.getExamSchools();
        }
        //获取老师列表
        // this.modelSearch.level = this.examInformation.level.toString();
        // this.modelSearch.year = this.examInformation.year;
        this.normalFetchList();
        //获取班级列表
        // this.changeClassOptions();
        //组装一个科目分工需要渲染的列表

        this.getExamunionwork();
        //获取信息
        // this.getDataList();
      });
    },
    submit() {},
    goBack() {
      this.$router.push({
        name: "examlist",
        query: { examId: this.$route.query.examId },
      });
    },
  },
};
</script>
<style lang="scss">
.ExamDistribute {
  .el-table__header-wrapper {
    th {
      background-color: #f2f5f8;
    }
  }
  .exam-distribute {
    .el-table .el-table__cell {
      padding: 12px 0;
    }
  }
  .subject-distribute-table {
    .el-table .el-table__cell {
      padding: 6.5px 0;
    }
  }
  .el-table-subject {
    .subject-name {
      margin-right: 6px;
    }
    // .el-button {
    //   display: none;
    //   margin: 0 auto;
    // }
    // &:hover {
    //   .subject-name {
    //     display: none;
    //   }
    //   .el-button {
    //     display: block;
    //   }
    // }
  }
  .el-table-box {
    cursor: pointer;
    .el-button {
      padding: 3.5px 5px;
      color: #9f9f9f;
      border-radius: 4px;
      overflow: hidden;
      span {
        margin-left: 0;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.ExamDistribute {
  padding: 24px 0 60px;
  ::v-deep
    .el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background-color: #ffffff;
  }
  .btn-icon {
    width: 36px;
    height: 32px;
    background-color: #f2f6ff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    flex-shrink: 0;
    cursor: pointer;
    margin: 3px 4px;
    img {
      width: 16px;
    }
  }
  .subject-tab-teacher-list {
    border: 1px solid #d8d8d8;
    min-height: 42px;
    border-radius: 2px;
    padding: 0 24px;
    cursor: pointer;
  }
  .user-list {
    padding: 6px 8px;
    margin: 3px 4px;
    background-color: #f5f7fa;
    border-radius: 4px;
  }
  .tab-teacher-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .teacher-item {
    display: flex;
    align-items: flex-start;
  }
  .teacher-box {
    display: flex;
    .teacher-list-box {
      padding: 18px 24px;
      height: 338px;
      overflow: scroll;
      width: 350px;
      .teacher-list {
        color: $primary-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        i {
          cursor: pointer;
          font-size: 16px;
          color: #dee1e7;
        }
      }
      .el-checkbox {
        display: block;
        margin-bottom: 18px;
      }
    }
    .right-teacher-header {
      display: flex;
      justify-content: flex-end;
    }
    .teacher-header {
      padding: 9px 24px;
      background: #f5f7fa;
      width: 350px;
      .el-checkbox {
        margin-bottom: 0;
      }
    }
    > div {
      border: 1px solid #dee1e7;
      &:first-child {
        margin-right: 24px;
      }
    }
  }
  .search-box {
    display: flex;
    flex-wrap: wrap;
    .search-item.ts-item {
      justify-content: flex-end;
    }
    .search-input {
      i {
        cursor: pointer;
      }
    }
    .search-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 30px;
      width: 250.5px;
      .el-select {
        width: 166px;
        // margin-left: 8px;
        // margin-right: 24px;
      }
    }
  }
  .exam-distribute {
    background-color: #ffffff;
    margin-top: 24px;
    padding: 24px;
    .exam-distribute-title {
      margin: 24px 0;
    }
  }
  .header-box {
    background-color: #ffffff;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: #080a09;
      // margin-bottom: 24px;
      // width: 100%;
      .el-icon-arrow-left {
        cursor: pointer;
      }
    }
  }
}
</style>
